.authPage, .authPage > div {
    height: 100%;
}

.authPage {
    background-image: url("./../../assets/Hintergrund_Login.png");
    background-position: center;
    background-position-y: 75%;
    background-repeat: no-repeat;
    background-size: contain;
}

.authPage > div {
    justify-content: center;
    align-items: center;
}

.authPage .p-field {
    margin-top: 1em;
    margin-bottom: 1em;
}

.authPage .p-card-title {
    margin-top: 1em;
}

.p-card {
    box-shadow: 0px 0px 20px grey!important;
    padding: 2em 2em 0 2em;
    min-width: 20em;
}

.p-card-title {
    text-align: center;
}

.login-btn {
    border-radius: 5px!important;
    box-shadow: 0 0 10px lightgray;
    font-weight: bold!important;
    margin-bottom: 1em!important;
    text-transform: uppercase;
}

/* Überschreibt Standard Inputfeld aussehen*/
.authPage .p-inputtext {
    border: 0!important;
    border-bottom: 1px solid black!important;
    border-radius: 0!important;
}

.authPage .p-inputtext:enabled:focus {
    box-shadow: unset!important;
}

.forgot-link a,.forgot-link a:link,.forgot-link a:visited,.forgot-link a:hover,.forgot-link a:focus,.forgot-link a:active{
    color: inherit;
    /*text-decoration: none;*/
}