.entwurf-btn {
    margin-top: 3em !important;
    margin-bottom: 0em !important;
    width: 15em;
    text-align: center;
    text-decoration: none!important;
}

.entwurf-btn span {
    width: 100%;
}

.btn-edit-bild {
    text-decoration: none!important;
}

.bild-item {
    height: 600px!important;

    padding: 1em;
    font-size: 15px;
    margin-right: 2em!important;
    margin-bottom: 4em;
    margin-top: 10px!important;
}

.bild-item button {
    border-radius: 5px;
}

.grid-item {
    padding: 0;
    margin-top: 5px!important;

    box-sizing: content-box!important;
    /*height: 100%;*/
    height: 500px!important;
    /*max-height: 480px;*/
    font-size: small;
}

.grid-item-number {
    margin-left: 1em;
    font-weight: bold;
}

.fact-list {
    list-style-type: decimal;
    padding: 0;
    padding-left: 1em;
    margin: 0;
}

.trenner {
    background-color: #d3d3d399!important;
    /*line-height: 2em;*/
    height: 2em;
    padding-left: 1em;
    padding-top: 0.25em;

    font-weight: bold;
}

.p-col-12.trenner {
    height: 2em;
    padding: 0.25em 0 0 1em;
}

.bildinformationen {
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 5px;
    margin-bottom: 5px;
}

.p-grid.bildinformationen-oben {
    margin: 0!important;
    height: 180px;
}

.p-col-12.frage {
    height: 40px;
    /*padding: 0;*/
    overflow: auto;
}

.p-col-12.antwort {
    height: 60px;
    /*padding: 0;*/
    overflow: auto;
}

.p-col-12.unsichtbares {
    height: 140px;
    /*overflow: auto;*/
}

.unsichtbares-content {
    padding: 8px 1.5em 8px 8px;
}

.rc-scrollbars-thumb {
    background-color: #f99f2e!important;
}

.rc-scrollbars-track {
    /*background-color: lightgray;*/
}