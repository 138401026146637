.form-headline {
    background-color: lightgray;
    font-weight: 500;
    margin-top: 2em;
}

.form-grid h5 {
    margin-bottom: 10px;
}

.form-grid input::placeholder {
    color: #afafaf;
}

.btn-fn button {
    margin-right: 0.5em!important;
}

.input-full-width {
    width: 100%;
}

.input-full-width > ul {
    width: 100%;
}

.p-button-plain-white {
    background: white;
    color: black;
    border: 0;
    box-shadow: 0px 0px 5px grey;
    text-decoration: none!important;
}