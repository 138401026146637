.save-settings-btn {
    margin-left: 0!important;
}

.d-setting {
    margin-bottom: 2em;
}
.d-setting > label {
    display: inline-block;
    margin-bottom: 1em;
}