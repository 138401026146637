.content-col {
    /*padding-bottom: 5em!important;*/
}

.p-dataview > .p-dataview-content > .p-grid {
    border: 0;
}

.p-dataview > .p-dataview-content > .p-grid > .p-grid {
    /*border: 1px solid black!important;*/
    border: 0!important;

    padding: 0;
}

.p-dataview > .p-dataview-content > .p-grid .p-grid {
    padding: 0;
}

.p-dataview .p-paginator {
    justify-content: start;
}

.p-dataview .p-paginator-page.p-paginator-element.p-highlight {
    background-color: #f99f2e!important;
    color: white!important;
}

.grid .p-grid .p-col-2, .grid .p-grid .p-col-8 {
    padding: 0;
}

.grid .p-grid {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 1em;
}

.session-item-image {
    border-top: 2px solid lightgray;
    border-left: 2px solid lightgray;
    border-bottom: 2px solid lightgray;
}

.session-item-description {
    border-top: 2px solid lightgray;
    border-right: 2px solid lightgray;
    border-bottom: 2px solid lightgray;
}

.description-table thead th {
    background-color: #d3d3d399!important;
}

.description-table th, .description-table tr {
    height: 2em;
}

.session-item {
    font-size: small;
}

.session-item-fn {
    padding-left: 10px!important;
}

.session-item-fn button {
    border-radius: 4px;
    border-color: transparent!important;
    box-shadow: 0px 0px 6px gray;
    color: black!important;
    text-decoration: none!important;
}

.session-item-fn a {
    text-decoration: none!important;
}

