.sidebar .p-button {
    color: black;
    background-color: white;
    width: 90%;
    border-color: transparent;
    margin-bottom: 1em;
}

.sidebar a {
    text-decoration: none!important;
}

.sidebar .logo-links img, .sidebar .logo-links {
    position: fixed;
    top: 3em;
    width: 8em;
    left: 2em;
}

.button-logout {
    /*position: absolute;*/
    /*bottom: 10px;*/
    /*left: 10px;*/
    /*width: 10%!important;*/

    bottom: 5px!important;
    left: 25px;
    width: 14em!important;
    position: fixed!important;
    box-shadow: 0 0 5px gray;
    border-radius: 3px;
}
.button-logout > span {
    margin-right: auto;
    margin-left: auto;
    font-size: small!important;
}

.nav-link span {
    font-size: small!important;
}
.nav-link.active span {
    font-weight: bold;
}

.nav-link.unselected button {
    margin-right: 1em;
    box-shadow: 0 0 5px gray;
}

.nav-link.active button {
    margin-right: 0;
}