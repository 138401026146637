html, body, #root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.main-color {
  background-color: #f99f2e!important;
  border: 1px solid transparent;
}

.yellow-color {
  background-color: #ffea34!important;
  border: 1px solid transparent;
  color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-button {
  border-radius: 0;
}

.sidebarTD {
  padding: 0;
  border: 0;
  height: 100%;
}

.sidebarTD .sidebar {
  border: 0;
}

.sidebarTD button {
  border: 0;
}

.content-grid {
  height: 100%;
  margin: 0!important;
}

.content-col {
  height: 100%;
  overflow: auto;
  margin-top: 2em;
  padding-bottom: 4em;
}